<template>
   <div class="box box-primary">
            <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title text-right">List</h3>
            </div>
            <div class="box-body">
            <div class="row">
                <div class="col-md-12 mbottom-15">
                    <div class="">
                        <div class="col-md-6">
                        <div class="form-group has-error">
                            <label class="control-label text-left">List Name</label><input id="name" class="is-invalid form-control" type="text">
                            <div class="invalid-feedback"><span>List Name is required</span></div>
                        </div>
                        </div>
                        <!--v-if-->
                        <div class="col-md-6 mbottom-15">
                        <div class="input-group"><input id="value" type="text" class="form-control"><span class="input-group-btn"><button id="AddValue" class="btn btn-info btn-flat">Add Value</button></span></div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label text-left">Values</label>
                            <div class="domain-list"></div>
                        </div>
                        </div>
                    </div>
                    <div class="col-2"><button id="Create" class="btn btn-primary margin-r-5">Create</button><button id="Cancel" class="btn btn-link">Cancel</button></div>
                    <div class="col-md-10">
                        <!--v-if-->
                    </div>
                </div>
            </div>
        </div>
    </div>

   
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import Toggle from '@vueform/toggle'
import iServiceSwitch from '../components/iServiceSwitch.vue';
import NewListPropertyForm from '../components/NewListPropertyForm.vue';
import ListDetail from '../components/ListDetail.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'List Settings',
   components: {
    Breadcrumb,
    iServiceSwitch,
    Multiselect,
    NewListPropertyForm,
    Toggle,
    Multiselect,
    ListDetail
  },
  setup(){      
       
       var showDetails = reactive({showNew:false,deleteAlert:false, showListDetails:false,currentRow:''});
       var showNew =   reactive(false);
       var deleteAlert =  reactive(false);
       var show = reactive(false);
       var currentRow = '';

       function showNewAlert(){
         showDetails.showNew = true; 
         showDetails.deleteAlert = false;  
         showDetails.showListDetails = false;
         showDetails.active_el = '';
       }
       function showDeleteAlert(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = true;  
       }
       function showMessageDetails(val,row){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showListDetails=true;
         showDetails.active_el = row;
       }
       function cancel(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showListDetails=false;
       }

       return{cancel,showNew,deleteAlert,showDetails,showNewAlert,showDeleteAlert,showMessageDetails};
  }

}
</script>
