<template>
   <div class="box box-primary">
            <div class="box-header with-border">
                <i class="fa fa-list"></i>
                <h3 class="box-title text-right">List Configuration</h3>
            </div>
            <div class="box-body">
            <div class="row">
                
                <div class="col-md-12 mbottom-15">
                    <div class="">
                        <div class="col-md-12"><p class="text-danger">This standard List can not be renamed.</p></div>
                        <div class="col-md-6">
                        <div class="form-group has-error">
                            <label class="control-label text-left">List Name</label><input id="name" class="is-invalid form-control" type="text">
                            <div class="invalid-feedback"><span>List Name is required</span></div>
                        </div>
                        </div>
                        <!--v-if-->
                        <div class="col-md-6 mbottom-15">
                        <div class="input-group"><input id="value" type="text" class="form-control"><span class="input-group-btn"><button id="AddValue" class="btn btn-info btn-flat">Add Value</button></span></div>
                        </div>
                        <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label text-left">Values</label>
                            <div class="domain-list"></div>
                        </div>
                        </div>
                    </div>
                    <div class="col-2"><button id="Create" class="btn btn-primary margin-r-5">Create</button><button id="Cancel" class="btn btn-link">Cancel</button></div>
                    <div class="col-md-10">
                        <!--v-if-->
                    </div>
                </div>
            </div>
        </div>
    </div>

   
</template>